@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Work+Sans&display=swap');

@font-face {
  font-family: 'Monument Extended';
  src: local('Monument Extended'), url('assets/fonts/MonumentExtended-Regular.otf');
}
@font-face {
  font-family: 'Apercu';
  src: local('Apercu'), url('assets/fonts/Apercu Regular.otf');
}
@font-face {
  font-family: 'Apercu Pro';
  src: local('Apercu Pro'), url('assets/fonts/Apercu Pro Regular.otf');
}
@font-face {
  font-family: 'Romana BT';
  src: local('Romana BT'), url('assets/fonts/romanan.ttf');
}